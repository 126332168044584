import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Injectable()
export class KreditoruiVersionInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {

          if (event.headers.has("x-kwui-version")) {

            // Get local verison.
            let appService = this.injector.get(AppService);

            var localVersion = appService.appConfig.getConfig()?.webVersion;
            if (localVersion == null || localVersion == '0.0.0.0') return; // Skip local/debug which is always 0.0.0.0


            // Parse JSON 
            var parsed = JSON.parse(event.headers.get("x-kwui-version"));

            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
            let versionCheck = String(parsed.KreditorUI_Version).localeCompare(
              localVersion, undefined,
              {
                numeric: true,
                sensitivity: 'base'
              }
            );

            // -1 == Local version is higher
            // 0 == Identical
            // 1 == Parsed version is higher
            appService.newApplicationVersionAvailable$.next(versionCheck > 0);
          }
        }
      })
    );
  }
}
