export interface IContractClaimTypeSummary {
    claimTypeId: number;
    claimTypeCode: string;
    claimTypeDescription: string;
    statuses: IContractClaimStatusSummary[];

    isDefault: boolean;
    selectedStatus: IContractClaimStatusSummary;
    closingFeeText: string;
}

export interface IUpdatedContractsMultipleStatuses {   
    contractStatusId: number;
    claimTypeCode: string;
    newStatusId: number;
}

export interface IContractClaimStatusFee {
    parentCustomerNumber: number;
    fee?: number;
}

export interface IContractClaimStatusSummary {
    id: number;
    name: string;
    translation: string;
    translationEn: string;
    claimTypeId: number;
    claimTypeCode: string;
    ClaimTypeDescription: string
    profileType?: number;
    profileText: string;
    statusCode?: number;
    statusText: string;
    initiateClosingFee: boolean;
    workList: boolean;
}

export interface IFilterContracts {
    customerNumber?: number;
    contractType?: string;
    statusName?: string;
    minimumCapitalAmount?: number;
    minimumNumberOfInvoices?: number;
    scoreColor?: ScoreColorEnum;
    debtorType?: DebtorTypeEnum;
}

export enum DebtorTypeEnum {
    Person = 'Person',
    Company = 'Company'
}

export enum ScoreColorEnum {
    Red = 'Red',
    Yellow = 'Yellow',
    Green = 'Green'
}

export interface IContractSummary {
    id: number;
    contractParentId: number;
    contractReference: string;
    claimTypeId: number;
    claimTypeCode: string;
    claimTypeDescription: string;
    statusId: number;
    statusName: string;
    statusTranslationTag: string;
    statusChangedDate: Date;
    debtorName: string;
    score?: number;
    debtorAddress: string;
    debtorPostCode: string;
    lastCaseRegisteredDate: Date;
    numberOfCases: number;
    numberOfInvoicesWithResidual: number;
    numberOfCasesWithWarningStatuses: number;
    totalRest: number;
    totalCapital: number;
    totalFees: number;
    warnedTotalRest: number;
    warnedTotalCapital: number;
    warnedTotalFees: number;
    hasExternalStatus: boolean;
    lastExternalStatusUpdateSuccessful: boolean;
    contractClosingTypeId: number;

    selected: boolean;
}

export interface IClaimStatusStatistics {
    statusId: number;
    statusName: string;
    statusNameEn: string;
    numberOfContracts: number;
    restCapital: number;
}

export interface IContractCase {
    customerNumber: number;
    caseNumber: number;
    statusChangedDate: Date;
    statusCode: number;
    statusText: string;
    statusTextEn: string;
    registeredDate: Date;
    originalCapital: number;
    restCapital: number;
    originalTotal: number;
    restTotal: number;
    isWarnedCase: boolean;
    isWarningStatus: boolean;
}

export interface IExternalContractStatus {
    externalPartyName: string;
    changes: IExternalContractStatusChange[];
}

export interface IExternalContractStatusChange {
    changeType: ContractStatusChangeActionTypeEnum;
    dateTimeCreated: Date;
    dateTimeExecuted?: Date;
    success: boolean;
    message: string;
    exernalOrderId: string;
}

export enum ContractStatusChangeActionTypeEnum {
    NA = "NA",
    CreateClosingOrder = "CreateClosingOrder",
    FinishClosingOrder = "FinishClosingOrder",
    Reopen = "Reopen",
    SoftBlock = "SoftBlock",
    HardBlock = "HardBlock"
}

export interface IContractNote {
    id: number;
    text: string;
    userName: string;
    date: Date;
    internal: boolean;
    fromCase: boolean;
    caseNumber?: number;
}

export interface IContractDebtorInfo {
    contractReference: string;
    debtorName: string;
    debtorEmail: string;
    debtorPhone: string;
}

export interface IContractNoteEdit {
    id: number;
    text: string;
    internal: boolean;
    sendMessageToCaseHandler: boolean;
}

export interface IContractClosingType {
  closingTypeId: number;
  closingTypeDescription: string;
  closingTypeTranslationTag: string;
}

export interface IUpdateContractClosingTypeRequest{
  contractIdToUpdate: number;
  contractClosingTypeId: number;
}

export interface IContractExecutedAction {  
  caseActionId: number;
  sidraEngineActionId?: number;
  executedDate: Date;
  dueDate?: Date;
  sentByChannel?: CaseActionSentByEnum;
  actionDescription: string;
  jobType?: JobType;
  mobileNumber: number;
  documentIdentifier: string;
  receiver: string;
}

export enum CaseActionSentByEnum {
  JustExecuted = 0,
  Letter = 1,
  Email = 2,
  SMS = 3,
  Digital = 4,
  ElectronicInvoice = 5,
  Eboks = 6,
  Vipps = 7,
  EHF = 8
}

export enum JobType {
  Email = 0,
  SMS = 1,
  Print = 2,
  PullFromArchive = 4,
  Preview = 5,  
  FTP = 7
}
