import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AppService } from './services/app.service';
import { UrlService } from './services/url.service';
import { FirmService } from './shared/firm/firm.service';
import { LocalizationService } from './shared/localization/localization.service';
import { ISearchEngine, IToolbarItemLink, IUserPermissions, WebUserRight } from './shared/models';
import { CurrentCustomerService } from './services/current-customer.service';
import { SettingsService } from './services/settings.service';

//declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  MENU = [
    { translationTag: 'MainMenu.Overview', link: '/dashboard', name: 'dashboard' } as IToolbarItemLink,
    { translationTag: 'MainMenu.Reports', link: '/reports/customReports', name: 'reports' } as IToolbarItemLink,
    { translationTag: 'MainMenu.Cases', link: '/cases/lists', name: 'cases' } as IToolbarItemLink,
    { translationTag: 'MainMenu.Documents', link: '/documents', name: 'documents' } as IToolbarItemLink,
    { name: 'faq', link: 'information/faq', icon: 'fas fa-question' } as IToolbarItemLink,
    { name: 'user', icon: 'far fa-user' } as IToolbarItemLink,
    { name: 'admin', icon: 'fas fa-cog', guard: { userRole: 'SuperUser' } } as IToolbarItemLink,
  ];

  USER_SUB_MENU: IToolbarItemLink[] = [
    { name: 'languages' } as IToolbarItemLink,
    { translationTag: 'User.MyProfile', name: 'myProfile' } as IToolbarItemLink,
    { translationTag: 'Header.Logout', name: 'logout' } as IToolbarItemLink
  ]

  SUPERUSER_SUB_MENU: IToolbarItemLink[] = [
    { name: 'userList', translationTag: 'MainMenu.UserList', link: 'admin/userList', guard: { userRole: "SuperUser" } } as IToolbarItemLink,
    { name: 'addSubCustomer', translationTag: 'MainMenu.AddSubCustomer', link: 'admin/addSubCustomer', guard: { userRight: WebUserRight.AddSubCustomer, userRole: 'SuperUser' } } as IToolbarItemLink,
  ];

  SEARCH_ENGINES = [
    { name: 'Svea', isSelected: true, label: 'SearchBar.Placeholder' } as ISearchEngine,
    { name: 'MatchIT', isSelected: false, label: 'SearchBar.MatchIt.Placeholder', guard: { userRight: WebUserRight.MatchIt }, isDisabled: false } as ISearchEngine,
    { name: 'Iper', isSelected: false, label: 'SearchBar.Iper.Placeholder', guard: { userRight: WebUserRight.Iper }, alwaysDisplay: true, isDisabled: false } as ISearchEngine
  ];

  currentUrl: string;
  previousUrl: string;
  googleAnalyticsTrackingId: any;
  sveaAddress$: Observable<string>;
  customers$ = this.application.customers$.pipe(tap(list => {
    if (list?.length === 1) { this.currentCustomerService.change(list[0].number); }
  }));

  debugEnabled = false;
  user$ = this.application.user$;
  administrationSubMenuItems: IToolbarItemLink[] = [];
  userSubMenuItems
  menu: IToolbarItemLink[] = [];
  searchEngines: ISearchEngine[] = [];
  newApplicationVersionAvailable$ = this.application.newApplicationVersionAvailable$.asObservable();
  private readonly unsubscribe$ = new Subject();

  constructor(
    private localizationService: LocalizationService,
    private application: AppService,
    private router: Router,
    private urlService: UrlService,
    private configService: AppConfigService,
    private firmService: FirmService,
    private currentCustomerService: CurrentCustomerService,
    private settingsService: SettingsService) {
    this.setUpGoogleAnalytics(configService);
  }

  ngOnInit() {
    this.logEvents();
    this.localizationService.setupLanguage();

    const availableEngines$ = this.user$.pipe(mergeMap((user) => {
      if (user?.isAuthenticated) return this.settingsService.getSettingValueByName('Krediorweb_3rdPartySearch_AvailableEngines');
      return of('');
    }));

    combineLatest([this.user$, availableEngines$, this.application.getPermissions()])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([user, searchEnginesSetting, permissions]) => {
      if (user?.isAuthenticated) {
        this.determineMenuItems(permissions, user.code);
        this.searchEngines = this.determineSearchEngines(permissions, searchEnginesSetting);
      }
    });

    this.sveaAddress$ = this.firmService.getSveaAddress();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd)
            ,takeUntil(this.unsubscribe$))
      .subscribe((event: NavigationEnd) => {
        this.reportToGoogleAnalytics(event);

        this.previousUrl = this.currentUrl;
        this.urlService.setPreviousUrl(this.previousUrl);
        this.currentUrl = event.url;
      });
  }

  onCustomerChange(number: number) {
    this.currentCustomerService.change(number);
  }

  async reloadApplication() {
    this.application.newApplicationVersionAvailable$.next(false);
    await this.configService.loadAppConfig();
    window.location.reload();
  }

  private determineMenuItems(permissions: IUserPermissions, userCode: string) {
    this.menu = this.filterAccess(permissions, this.MENU);
    this.menu.filter(x => x.name == 'admin').map(x => { x.subMenu = this.filterAccess(permissions, this.SUPERUSER_SUB_MENU); return x });
    this.menu.filter(x => x.name == 'user').map(x => x.subMenu = this.getUserMenu(permissions, userCode));
  }

  private determineSearchEngines(permissions: IUserPermissions, availableSearchEngines: string): ISearchEngine[] {
    const availableSearchEnginesList = availableSearchEngines.split(';').map(x => x.toLocaleLowerCase());
    const userRights = (permissions?.rights ?? []);
    
    return this.SEARCH_ENGINES.filter(x => 
      availableSearchEnginesList.includes(x.name.toLocaleLowerCase()) && 
        (x.guard == null || 
        userRights.indexOf(x.guard?.userRight) >= 0 || 
        x.alwaysDisplay)
      ).map(x => {
        if (x.guard && userRights.indexOf(x.guard?.userRight) < 0) x.isDisabled = true;
        else x.isDisabled = false;
        return x;
    });
  }

  private filterAccess(permissions: IUserPermissions, items: IToolbarItemLink[]): IToolbarItemLink[] {
    const userRights = permissions?.rights ?? [];
    return items.filter(item =>
      (item.guard?.userRight == null ||
        userRights.indexOf(item.guard?.userRight) >= 0) &&
      (item?.guard?.userRole == null ||
        item?.guard?.userRole == permissions?.role));
  }

  private logEvents() {
    this.application.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(u => this.log(`User changed: '${u.code}' isAuthenticated: ${u.isAuthenticated}`));
    this.application.customerNumber$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(nr => this.log(`Customer number changed: '${nr}`));
    this.application.customer$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(c => this.log(`Customer changed: '${c?.number} ${c?.name}`));
    this.application.customers$      
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(list => this.log(`Customer list changed: '${list?.length}`));
  }

  private setUpGoogleAnalytics(configService: AppConfigService) {
    this.googleAnalyticsTrackingId = configService.getConfig().googleAnalyticsTrackingId
    if (this.googleAnalyticsTrackingId == null) { return; }
    gtag('config', this.googleAnalyticsTrackingId);
  }

  private reportToGoogleAnalytics(event: NavigationEnd): void {
    if (this.googleAnalyticsTrackingId == null) { return; }
    gtag('config', this.googleAnalyticsTrackingId,
      {
        'page_path': event.urlAfterRedirects
      }
    );
  }

  private log(data: any) { if (this.debugEnabled) { console.log(data); } }

  private getUserMenu(permissions: IUserPermissions, userCode: string) {
    return this.USER_SUB_MENU.map(x => {
      if (x.name == 'myProfile') {
        if (permissions?.role == "SuperUser") x.link = '/admin/user/' + userCode;
        else x.link = '/user/profile';
      }
      return x;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
