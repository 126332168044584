import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { LoginService } from 'src/app/login/login.service';
import { AppService } from 'src/app/services/app.service';
import { ICustomerSummary, ISearchEngine, IToolbarItemLink } from 'src/app/shared/models';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnChanges {

  @Input()
  menuItems: IToolbarItemLink[] = [];
  @Input()
  searchEngines: ISearchEngine[];
  @Input()
  customers: ICustomerSummary[];
  @Output()
  customerNumberChange = new EventEmitter<number>();

  constructor (
    private router: Router,
    private loginService: LoginService, 
    public appService: AppService,
    public configService: AppConfigService
    ) { }

  mainMenuItems:  IToolbarItemLink[];
  iconMenuItems:  IToolbarItemLink[];
  withSubMenuItems: IToolbarItemLink[];

  ngOnInit(): void {
    this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    map(event => event as NavigationStart)).subscribe(_ => {
      const url = _.url.split('?')[0];
      this.selectMenuItem(url);
      this.selectMainMenuItem(url);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['menuItems']?.currentValue) {
      this.mainMenuItems = this.menuItems.filter(x => x.icon == null);
      this.iconMenuItems = this.menuItems.filter(x => x.icon?.length > 0);
      this.withSubMenuItems = this.menuItems.filter(x => x.subMenu?.length > 0);
    }
  }

  onCustomerChange(number: number) {
    this.customerNumberChange.emit(number);
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['']);
  }

  private selectMainMenuItem(url: string) {
    const navigationUrlRoot = url.split('/')[1];
    this.mainMenuItems = this.mainMenuItems.map(x => {
      const itemUrlRoot = x.link.split('/')[1];
      x.isSelected = navigationUrlRoot == itemUrlRoot;
      return x;
    });
  }

  private selectMenuItem(url: string) {
    this.menuItems = this.menuItems.map(x => {
      x.isSelected = x.link == url;
      return x;
    });
  }
}
