import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { CurrentCaseService } from './current-case.service';
import { LoginService } from '../login/login.service';
import { CustomerLetterType, IAppUser, ICustomerSummary, IUserPermissions, KreditorReportType as IKreditorReportType, ServiceResult, UserRole, WebUserRight } from '../shared/models';
import { CurrentCustomerService } from './current-customer.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { catchError, first, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { CustomersService } from './customers.service';
import { KeyValue } from '@angular/common';
import { AppConfigService } from '../app-config.service';
import { SimpleCache } from '../shared/simple-cache';
import { KreditorService } from '../shared/kreditor.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  newApplicationVersionAvailable$ = new BehaviorSubject(false);

  constructor(
    private loginService: LoginService,
    private currentCustomerService: CurrentCustomerService,
    private currentCaseService: CurrentCaseService,
    private localization: LocalizationService,
    private customersService: CustomersService,
    public appConfig: AppConfigService,
    private kreditorService: KreditorService) {

    this.loginService.currentUser$.pipe(mergeMap(u => {
      return (u.isAuthenticated ? this.customersService.getCustomers() : of(null));
    })).subscribe(_ => this.customersSubject.next(_));
  }

  private customersSubject = new BehaviorSubject<ICustomerSummary[]>([]);

  get customers$(): Observable<ICustomerSummary[]> {
    return this.customersSubject.asObservable();
  }

  get customersSelectItems$(): Observable<KeyValue<number, string>[]> {
    return this.customers$.pipe(map(_ => {
      return _?.map(x => { return { key: x.number, value: `${x.number} - ${x.name}` } });
    }));
  }

  get user$(): Observable<IAppUser> {
    return this.loginService.currentUser$;
  }

  get customerNumber$(): Observable<number> {
    return this.currentCustomerService.selectedNumber$;
  }

  get customer$(): Observable<ICustomerSummary> {
    return this.currentCustomerService.selectedNumber$
      .pipe(mergeMap(cusNumber => cusNumber == null ? of(null) : this.customersService.getCustomer(cusNumber)));
  }

  get case$(): Observable<number> {
    return this.currentCaseService.selectedCaseNumber$;
  }

  get localization$(): Observable<any> {
    return this.localization.settings$;
  }

  _permissionsCache = new SimpleCache<IUserPermissions>({ 
    lifetime: 120, 
    loadFunction: () => this.user$.pipe(
      shareReplay(1),
      switchMap(_ => { 
      if (_.isAuthenticated) return this.readPermissions();
      return of(null)
    }))
  });

  getPermissions() { return this._permissionsCache.data$; }

  private readPermissions(): Observable<IUserPermissions> {
    return this.kreditorService.get<ServiceResult<IUserPermissions>>('users/permissions')
      .pipe(map(_ => _.result), catchError(() => of({} as IUserPermissions)))
  }

  userHasRight(right: WebUserRight): Observable<boolean> {
    return this.getPermissions().pipe(map(_ => right == null || _.rights.findIndex(x => x == right) >= 0));
  }

  userDaysToDebtCollection(): Observable<number> {
    return this.getPermissions().pipe(map(u => u?.settingNumberOfDaysDebtCollectionList ?? 0));
  }

  reloadCurrentCase(): void {
    this.currentCaseService.selectedCaseNumber$.pipe(first()).subscribe(nr => {
      this.currentCaseService.selectCaseNumber(null);
      this.currentCaseService.selectCaseNumber(nr);
    });
  }

  getAvailableDocumentTypes(): Observable<CustomerLetterType[]> {
    return this.kreditorService.getServiceResult<CustomerLetterType[]>('users/documentTypes')
      .pipe(map(_ => _.result));
  }

  getAvailableReports(): Observable<IKreditorReportType[]> {
    return this.kreditorService.getServiceResult<IKreditorReportType[]>('users/reports')
    .pipe(map(_ => _.result));
  }
}
