<div class="navigation">
  <nav class="navigation-menu">
    <a [routerLink]="['dashboard']">
      <img src="./assets/sveaLogo.png" alt="Svea Logo" class="svea-logo">
    </a>
    <a *ngFor="let menuItem of mainMenuItems" class="navigation-menu-item"
      [ngClass]="{'selected': menuItem.isSelected }" [routerLink]="menuItem.link">
      {{ menuItem.translationTag | translate }}
    </a>
    <a class="navigation-mobile" subMenuTrigger subMenuId="mainSubMenu">
      {{ 'MainMenu.Menu' | translate}}
    </a>
  </nav>
  <div class="navigation-search">
    <app-search-bar [searchEngines]="searchEngines"></app-search-bar>
  </div>
  <div class="navigation-customer">
    <app-customer-select (customerNumberChange)="onCustomerChange($event)" [customers]="customers">
    </app-customer-select>
  </div>

  <div class="navigation-sub" id="mainSubMenu">
    <a *ngFor="let menuItem of menuItems" class="navigation-sub-item"
      [ngClass]="{'selected': menuItem.isSelected}" [routerLink]="menuItem.link">
      {{ menuItem.translationTag | translate }}
    </a>
  </div>

  <div class="navigation-icons">
    <ng-container *ngFor="let item of iconMenuItems">
      <ng-container *ngTemplateOutlet="iconMenu; context:{ item: item}"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngFor="let item of withSubMenuItems">
    <ng-container *ngTemplateOutlet="subMenu; context:{ name: item.name, items: item.subMenu }"></ng-container>
  </ng-container>


  <ng-template let-item="item" #iconMenu>
    <div class="navigation-icons-item">
      <ng-container *ngIf="item.subMenu?.length > 0; else simpleIcon">
        <a subMenuTrigger subMenuId="{{item.name + 'SubMenu'}}" subMenuDisplayType="flex">
          <i [class]="item.icon"></i>
        </a>
      </ng-container>
      <ng-template #simpleIcon>
        <a [routerLink]="item.link">
          <i [class]="item.icon"></i>
        </a>
      </ng-template>
    </div>
  </ng-template>

  <ng-template let-name="name" let-items="items" #subMenu>
    <div class="navigation-user" [id]="name + 'SubMenu'">
      <ng-container *ngFor="let item of items" [ngSwitch]="item.name">
        <ng-container *ngSwitchCase="'languages'">
          <app-language-menu></app-language-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'logout'">
          <a class="navigation-user-item" (click)="logout()">
            {{ item.translationTag | translate }}
          </a>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <a class="navigation-user-item" [routerLink]="item.link">
            {{ item.translationTag | translate }}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

